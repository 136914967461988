import { Environment } from '../types/app';

const configStore = {
  production: {
    vpApi: {
      url: 'https://vision-project-pxyz.appspot.com',
    },
  },
  staging: {
    vpApi: {
      url: 'https://vision-project-dev.appspot.com',
    },
  },
  development: {
    vpApi: {
      url: process.env.VP_API_URL ?? 'https://vision-project-dev.appspot.com',
    },
  },
  test: {
    vpApi: {
      url: 'http://vp-api-test.com',
    },
  },
};

const environment: Environment = (process.env.NODE_ENV ??
  Environment.DEVELOPMENT) as Environment;

export const config = configStore[environment];
