export const getUserIdFromParams = (params: URLSearchParams): string => {
  // the order of this array is important as
  // we use it to prioritize the value of ext
  // This code is the same as the one in the mobile landing page
  const platformUserIdKeys: string[] = [
    'ext', // kantar
    'state', // kantar
    'user_id', // mturk && click-worker
    'workerId', // mturk premium
  ];
  const key = platformUserIdKeys.find((key: string): string | null =>
    params.get(key)
  );
  return (key && params.get(key)) ?? '';
};
